import { getImageProps } from 'next/image';

import React from 'react';

type Props = {
  height: number
  media: string
  sizes?: string
  src: string
  width: number
};

// Note: omitting a sizes prop will return 1x and 2x images as the srcSet.

const PictureSource: React.FC<Props> = ({ src, width, height, media, sizes }) => {
  const { props: { srcSet: set } } = getImageProps({
    alt: '',
    height,
    sizes,
    src,
    width,
  });

  return (
    <source media={ media } srcSet={set} />
  );
};

export default PictureSource;
