import React from 'react';
import styles from './CharacterCard.module.scss';
import Image from 'next/image';
import PictureSource from '@/components/base/PictureSource';

interface Props {
  className?: string
  characterImage: string
  navigationCardLogo: string
  characterImageBackground?: string
  characterImageBackgroundCrop?: string
  characterImageClassName?: string
  navigationCardLogoClassName?: string
  backgroundClassName?: string
}

const CharacterCard: React.FC<Props> = ({
  className,
  characterImage,
  navigationCardLogo,
  characterImageBackground,
  characterImageBackgroundCrop,
  characterImageClassName,
  navigationCardLogoClassName,
  backgroundClassName,
}) => {
  return (
    <div
      data-pbsk-component-character-card
      className={`
        ${ styles.card }
        ${ className || '' }
      `}
    >
      <div className={ `${styles.backgroundContainer} ${backgroundClassName || ''}` }>
        {characterImageBackground && characterImageBackgroundCrop && <picture className={ styles.background }>
          <PictureSource
            media='(max-width: 767px)'
            src={characterImageBackgroundCrop}
            width={72}
            height={72}
          />
          <Image
            src={ characterImageBackground }
            width='170'
            height='73'
            sizes='(max-width: 600px) 75px, 170px'
            alt=''
          />
        </picture>}
      </div>

      <Image
        className={ `${styles.logo} ${navigationCardLogoClassName || ''}` }
        src={ navigationCardLogo }
        width='200'
        height='73'
        alt=''
      />

      <Image
        className={ `${styles.character} ${characterImageClassName || ''}` }
        src={ characterImage }
        width='130'
        height='100'
        sizes='(max-width: 600px) 80px, 130px'
        alt=''
      />
    </div>
  );
};

export default CharacterCard;
