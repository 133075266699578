import React, { useContext } from 'react';

import styles from './ArrowButton.module.scss';
import ChevronLeft from '@/public/puma/images/icons/chevron_left.svg';
import LanguageLocalizer from '@/utils/language-localization';

interface Props {
  onClick?: () => void
  edgeStyle?: boolean
  disabled?: boolean
  ariaLabelText?: string
  moreClasses?: string
}

const ArrowButtonLeft: React.FC<Props> = ({
  onClick,
  edgeStyle,
  disabled,
  ariaLabelText,
  moreClasses,
}) => {
  const { __t } = new LanguageLocalizer( useContext, 'components/base/ArrowButton' );
  return (
    <button
      className={`
        ${ styles.baseStyles }
        ${ edgeStyle ? styles.edgeStyles + ' ' + styles.edgeStylesLeft : '' }
        ${ moreClasses || '' }
      `}
      aria-label={ ariaLabelText || __t('arrowButtonPreviousDefaultLabel') }
      data-carousel-nav-button-direction='prev'
      onClick={ onClick }
      disabled={ disabled }
    >
      <ChevronLeft
        className={ `${ styles.iconBase } ${ styles.iconBaseLeft } ${ edgeStyle ? styles.iconEdge : '' }` }
      ></ChevronLeft>
    </button>
  );
};

export default ArrowButtonLeft;
