// based on https://zellwk.com/blog/keyboard-focusable-elements/
const focusableSelector = `
a[href],
button,
[type='button'],
input[type='text'],
input[type='reset'],
input[type='range'],
input[type='submit'],
textarea,
select,
details,
embed,
object,
iframe
`;

const getFocusable = (parentNode = document) => {
  return Array.prototype.slice.call( parentNode.querySelectorAll(focusableSelector) )
    .filter((el) => {
      return (
        !el.hasAttribute('disabled') &&
        !el.getAttribute('aria-hidden') &&
        el.style.display !== 'none'
      );
    });
};

export {
  getFocusable,
};
