import { CreatePluginType, LoosePluginType } from 'embla-carousel/components/Plugins';
import { debounce } from 'ts-debounce';
import { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import { getFocusable } from '@/utils/focusable';
import { OptionsType } from 'embla-carousel/components/Options';
import { UseEmblaCarouselType } from 'embla-carousel-react';
import { ClientSideFeatureFlag } from '@/managers/FeatureFlags';
import useEmblaCarousel from 'embla-carousel-react';

import Logger from '@/utils/logger';
const logger = new Logger({ caller: 'managers.Layout.useAccessibleCarousel' });

const allCarousels: Array<EmblaCarouselType> = [];
const elementsWithFocusEvents: Array<Element> = [];
const elementsThatDontNeedFocusEvents: Array<Element> = [];
const originalTabIndexes: Array<{ element: Element, originalTabIndexValue: string }> = [];
const debugCarouselAccessibility = new ClientSideFeatureFlag('debugCarouselAccessibility', 'boolean', '');

const placeIndexesInVisualOrder = (inViewIndexes: number[]) => {
  let reorderStartingPoint = 0;

  for (let index = 0; index < inViewIndexes.length; index++) {
    const inViewIndex = inViewIndexes[index];
    const lastInViewIndex = inViewIndexes[index - 1];
    if (typeof lastInViewIndex !== 'undefined' && inViewIndex !== lastInViewIndex + 1) {
      reorderStartingPoint = index;
    }
  }
  if (reorderStartingPoint > 0) {
    return inViewIndexes.slice(reorderStartingPoint).concat(inViewIndexes.slice(0, reorderStartingPoint));
  } else {
    return inViewIndexes;
  }
};

const getFocusableSlideNodes = (emblaApi: EmblaCarouselType): Array<HTMLElement> => {
  return emblaApi.slideNodes().map((ele) => {
    const link = ele.querySelector('a');
    if (!link) {
      ele.setAttribute('tabindex', '0');
    }
    return link ?? ele;
  });
};

const getVisibleSlides = (emblaApi: EmblaCarouselType, inVisualOrder = false) => {
  const slideNodes = getFocusableSlideNodes(emblaApi);
  const inViewIndexes = emblaApi.slidesInView();
  const indexOrder = inVisualOrder ? placeIndexesInVisualOrder( inViewIndexes ) : inViewIndexes;
  return indexOrder.map((index) => slideNodes[index]);
};

const getWrapperElement = (emblaApi: EmblaCarouselType) => {
  const wrapperElement = emblaApi.containerNode().closest('[data-carousel-wrapper="true"]');

  if (!wrapperElement) {
    throw new Error('carousel wrapper element not found');
  }
  return wrapperElement;
};

const setTabIndex = (ele: Element, index: number | null) => {
  // Only change tab indexes if they weren't set at page load
  let originalTabIndex = originalTabIndexes.find((item) => item.element === ele);

  if (!originalTabIndex) {
    originalTabIndex = {
      element: ele,
      originalTabIndexValue: ele.getAttribute('tabindex') ?? '',
    };
    originalTabIndexes.push( originalTabIndex );
  }

  if (originalTabIndex.originalTabIndexValue) {
    logger.debug(`tabindex was already set to ${originalTabIndex.originalTabIndexValue}. not setting to ${index}.`);
    return;
  }

  if (index === null) {
    ele.removeAttribute('tabindex');
  } else {
    if (index > 0) {
      const dupe = document.querySelector(`[tabindex="${index}"]`);
      if (dupe && originalTabIndexes.find((item) => item.element === dupe && !item.originalTabIndexValue)) {
        dupe.removeAttribute('tabindex');
      }
    }
    ele.setAttribute('tabindex', index.toString());
  }
};

const toggleVisibilityToScreenReaders = (ele: Element, visible: boolean) => {
  // For screen readers, we shouldn't have screen readers read the content of slides that aren't currently visible, per
  // https://www.smashingmagazine.com/2023/02/guide-building-accessible-carousels/#the-slides
  if (visible) {
    ele.removeAttribute('aria-hidden');
  } else {
    ele.setAttribute('aria-hidden', 'true');
  }
};

const updateSlideCount = (emblaApi: EmblaCarouselType, emblaOptions: EmblaOptionsType) => {
  const inViewIndexes = emblaApi.slidesInView();

  if (inViewIndexes.length > 0 && emblaOptions.slidesToScroll !== inViewIndexes.length) {
    emblaOptions.slidesToScroll = inViewIndexes.length;
  }
};

const getAdjacentElement = (sourceElement: Element, elements: Array<Element>, offset: number) => {
  const index = elements.indexOf(sourceElement);
  if (index > -1) {
    return elements[index + offset];
  }
};

const getNavButtons = (emblaApi: EmblaCarouselType) => {
  const wrapperElement = getWrapperElement(emblaApi);
  const nextButton = wrapperElement.querySelector('[data-carousel-nav-button-direction="next"]');
  const prevButton = wrapperElement.querySelector('[data-carousel-nav-button-direction="prev"]');

  if (!nextButton || !prevButton) {
    throw new Error('nav buttons not found');
  }
  return { prevButton, nextButton };
};

// This function gives us the desired focus order of elements within a carousel
const getFocusableInCarousel = (emblaApi: EmblaCarouselType, onlyVisibleSlides: boolean) => {
  const { prevButton, nextButton } = getNavButtons(emblaApi);
  const slides = onlyVisibleSlides ? getVisibleSlides(emblaApi, true) : getFocusableSlideNodes(emblaApi);
  return [ prevButton, ...slides, nextButton ];
};

// This function gives us the desired focus order of elements in the entire page
const getFocusableInPageInDesiredOrder = () => {
  const newOrder: Array<Element> = [];
  const focusableInPage = getFocusable();

  const justInCarousels = allCarousels.map((carousel) => getFocusableInCarousel(carousel, true));
  const justInCarouselsFlattened = justInCarousels.flat();

  const removeAllCarouselElements = (elements: Array<Element>) => {
    return elements.filter((ele) => !justInCarouselsFlattened.includes(ele));
  };

  for (let carouselIndex = 0; carouselIndex < justInCarousels.length; carouselIndex++) {
    const thisCarouselElements = justInCarousels[carouselIndex];
    const prevCarouselElements = justInCarousels[carouselIndex - 1];
    const nextCarouselElements = justInCarousels[carouselIndex + 1];

    // Pre
    const preCarouselBetween = removeAllCarouselElements( focusableInPage.slice(
      // start
      prevCarouselElements ?
        focusableInPage.indexOf(prevCarouselElements[prevCarouselElements.length - 1]) : 0,

      // end
      focusableInPage.indexOf(thisCarouselElements[0]),
    ) );

    // Post
    const postCarouselBetween = carouselIndex < justInCarousels.length - 1 ? [] :
      removeAllCarouselElements( focusableInPage.slice(
        // start
        focusableInPage.indexOf(thisCarouselElements[thisCarouselElements.length - 1]) + 1,

        // end
        nextCarouselElements ?
          focusableInPage.indexOf(nextCarouselElements[0]) - 1 : undefined,
      ) );

    const addToNewOrder = [
      ...preCarouselBetween,
      ...thisCarouselElements,
      ...postCarouselBetween,
    ];

    newOrder.push(...addToNewOrder);
  }
  return newOrder;
};

const getCarouselNeighbors = (emblaApi: EmblaCarouselType, count = 2) => {
  const { nextButton, prevButton } = getNavButtons(emblaApi);
  const focusableInOrder = getFocusableInPageInDesiredOrder();
  const prevNeighbors = [];
  const nextNeighbors = [];

  for (let i = 1; i <= count; i++) {
    prevNeighbors.push(getAdjacentElement(prevButton, focusableInOrder, -1 * i));
    nextNeighbors.push(getAdjacentElement(nextButton, focusableInOrder, i));
  }
  return { prevNeighbors, nextNeighbors };
};

const getTabIndex = (ele: Element | null | undefined) => {
  const index = ele?.getAttribute('tabindex');
  if (index) {
    return parseInt(index);
  }
  return null;
};

const debugTabIndexState = () => {
  if (debugCarouselAccessibility.value === true) {
    const allWithTabIndex = Array.prototype.slice.call(
      document.querySelectorAll('[tabindex]:not([tabindex="-1"])'),
    );
    const sorted = allWithTabIndex.sort((a, b) => {
      return (getTabIndex(a) || 0) - (getTabIndex(b) || 0);
    });

    if (sorted.length < 3 && sorted.length > 0) {
      logger.error('less than 3 focusable elements found!');
    }

    logger.debug(
      'debugTabIndexState',
      'activeElement:',
      document.activeElement,
      'Assigned tabindexes:',
      sorted,
    );
  }
};

const resetTabIndexes = () => {
  const allFocusable = getFocusable();
  const visibleSlides: Element[] = [];
  const invisibleSlides: Element[] = [];

  for (let i = 0; i < allCarousels.length; i++) {
    const emblaApi = allCarousels[i];
    const allTheseSlides = getFocusableSlideNodes(emblaApi);
    const theseVisibleSlides = getVisibleSlides(emblaApi);
    const theseInvisibleSlides = allTheseSlides.filter((slide) => !theseVisibleSlides.includes(slide));

    visibleSlides.push(...theseVisibleSlides);
    invisibleSlides.push(...theseInvisibleSlides);
  }

  for (let i = 0; i < allFocusable.length; i++) {
    const ele = allFocusable[i];
    if (invisibleSlides.includes(ele)) {
      toggleVisibilityToScreenReaders(ele, false);
      setTabIndex(ele, -1);
    } else {
      if (visibleSlides.includes(ele)) {
        toggleVisibilityToScreenReaders(ele, true);
      }
      setTabIndex(ele, null);
    }
  }
};

const updateTabIndexes = () => {
  // eslint-disable-next-line no-use-before-define
  setupFocusEventHandlers();
  resetTabIndexes();

  const activeElement = document.body === document.activeElement ? null : document.activeElement;

  if (activeElement) {
    const allFocusableInOrder = getFocusableInPageInDesiredOrder();
    const prevElement = getAdjacentElement(activeElement, allFocusableInOrder, -1);
    const nextElement = getAdjacentElement(activeElement, allFocusableInOrder, 1);

    if (prevElement && nextElement) {
      setTabIndex(prevElement, 1);
      setTabIndex(activeElement, 2);
      setTabIndex(nextElement, 3);
    }
  }

  debugTabIndexState();
};

const isInCarousel = (emblaApi: EmblaCarouselType, ele: Element, onlyVisibleSlides: boolean) =>
  getFocusableInCarousel(emblaApi, onlyVisibleSlides).includes(ele);

const isElementThatEffectsCarousel = (ele: Element, emblaApi: EmblaCarouselType) => {
  const { prevNeighbors, nextNeighbors } = getCarouselNeighbors(emblaApi);
  return isInCarousel(emblaApi, ele, false) || prevNeighbors.includes(ele) || nextNeighbors.includes(ele);
};

const setupFocusEventHandlers = () => {
  const allFocusable = getFocusableInPageInDesiredOrder();
  for (let i = 0; i < allFocusable.length; i++) {
    const ele = allFocusable[i];
    if (!elementsWithFocusEvents.includes(ele) && !elementsThatDontNeedFocusEvents.includes(ele)) {
      const effectsACarousel = !!allCarousels.find((emblaApi) => isElementThatEffectsCarousel(ele, emblaApi));
      if (!effectsACarousel) {
        elementsThatDontNeedFocusEvents.push(ele);
      } else {
        elementsWithFocusEvents.push(ele);
        ele.addEventListener('blur', resetTabIndexes);
        ele.addEventListener('focus', () => setTimeout(updateTabIndexes, 0));
      }
    }
  }
};

const applyDynamicWidthCarouselBehavior = (
  emblaApi: EmblaCarouselType,
  emblaOptions: EmblaOptionsType,
) => {
  // This update has to occur so that Embla can handle tabbing through the carousel without automatically scrolling on each press of tab
  const onEmblaUpdate = () => updateSlideCount(emblaApi, emblaOptions);
  emblaApi.on('resize', onEmblaUpdate);
  emblaApi.on('slidesInView', onEmblaUpdate);
  onEmblaUpdate();
};

const applyAccessibilityEnhancements = (emblaApi: EmblaCarouselType) => {
  const debounced = debounce( () => updateTabIndexes(), 25 );
  const onEmblaUpdate = () => debounced();
  emblaApi.on('resize', onEmblaUpdate);
  emblaApi.on('slidesInView', onEmblaUpdate);
  emblaApi.on('settle', onEmblaUpdate);
  emblaApi.on('init', onEmblaUpdate);
};

const useAccessibleCarousel = (
  emblaOptions?: Partial<OptionsType> | undefined,
  // eslint-disable-next-line @typescript-eslint/ban-types
  plugins?: CreatePluginType<LoosePluginType, {}>[] | undefined,
): UseEmblaCarouselType => {
  const [ emblaRef, emblaApi ] = useEmblaCarousel(emblaOptions, plugins);

  if (emblaApi) {
    applyDynamicWidthCarouselBehavior(
      emblaApi,
      emblaOptions || {},
    );

    if (!allCarousels.includes(emblaApi)) {
      allCarousels.push(emblaApi);
      applyAccessibilityEnhancements( emblaApi );
    }
  }

  return [ emblaRef, emblaApi ];
};

export { useAccessibleCarousel };
